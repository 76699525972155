<!-- eslint-disable rulesdir/no-retired-components -->
<template>
  <div :class="['komodo-modal', theme]">
    <div
      class="komodo-modal-background"
      @click.stop.prevent="backgroundClose && $emit('close')"
    />

    <div class="komodo-modal-card">
      <div
        v-if="titleWithInlineClose"
        class="komodo-modal-card-close inline-close"
      >
        <div class="komodo-modal-card-title">
          {{ title }}
        </div>
        <div
          v-if="slots.tooltip"
          class="komodo-modal-tooltip"
        >
          <slot name="tooltip" />
        </div>
        <komodo-icon
          class="komodo-modal-close color-action-blue-base"
          icon="clear"
          data-test="modal-close-button"
          @click="$emit('close')"
        />
      </div>
      <template v-else>
        <div
          v-if="showClose"
          class="komodo-modal-card-close"
        >
          <komodo-icon
            class="komodo-modal-close"
            icon="clear"
            data-test="modal-close-button"
            @click="$emit('close')"
          />
          <div
            v-if="slots.info"
            class="komodo-modal-info"
          >
            <slot name="info" />
          </div>
        </div>
        <div
          v-if="title"
          class="komodo-modal-card-title"
        >
          {{ title }}
        </div>
      </template>
      <div
        v-if="slots.header"
        class="komodo-modal-card-header"
      >
        <slot name="header" />
      </div>
      <div class="komodo-modal-card-body">
        <slot name="body" />
      </div>
      <div
        v-if="slots.footer"
        class="komodo-modal-card-footer-wrapper"
      >
        <div
          class="komodo-modal-card-footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable rulesdir/no-retired-components */
import { onMounted, onBeforeUnmount, PropType, useSlots } from 'vue'

import KomodoIcon from 'shared/components/KomodoIcon.vue'

export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

export default {
  name: 'KomodoModal'
}
</script>

<script setup lang="ts">
defineProps({
  theme: {
    type: String as PropType<Theme>,
    default: Theme.Dark
  },
  title: {
    type: String,
    default: undefined
  },
  titleWithInlineClose: {
    type: Boolean,
    required: false
  },
  showClose: {
    type: Boolean,
    default: true
  },
  backgroundClose: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits<{ (e: 'close'): void }>()

const slots = useSlots()

onMounted(() => {
  document.addEventListener('keydown', handleKeyPress, false)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyPress, false)
})

const handleKeyPress = (event) => {
  if (event.code === 'Escape') {
    emit('close')
  }
}
</script>

<style lang="scss" scoped>
$border-radius-amount: 12px;

.komodo-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 115;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.dark {
    .komodo-modal-background {
      background-color: rgba(10, 10, 10, 0.86);
    }

    .komodo-modal-card {
      box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
        0px 24px 38px rgba(0, 0, 0, 0.14);
      border-radius: $border-radius-amount;
    }
  }

  &.light {
    .komodo-modal-background {
      background-color: rgba(235, 246, 255, 0.41);
    }

    .komodo-modal-card {
      border-radius: $border-radius-amount;
    }
  }
}

.komodo-modal-card > div:first-child {
  border-radius: $border-radius-amount $border-radius-amount 0 0;
}

.komodo-modal-card > div:last-child {
  border-radius: 0 0 $border-radius-amount $border-radius-amount;
}

.komodo-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.komodo-modal-card {
  @include font-paragraph-p1-baseline;

  color: black;
  margin: 0 20px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  overflow-y: visible;
}

$grid-breakpoint-md: map-get($grid-breakpoints, 'md');

@media screen and (min-width: $grid-breakpoint-md), print {
  .komodo-modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
}

.komodo-modal-card-close,
.komodo-modal-card-header,
.komodo-modal-card-body,
.komodo-modal-card-footer,
.komodo-modal-card-footer-wrapper {
  background-color: white;
  white-space: normal;
  padding-left: 1em;
  padding-right: 1em;

  .komodo-modal-card-footer {
    padding: 1em 0;
  }
}

.komodo-modal-card-close {
  display: flex;
  height: 56px;

  .komodo-icon-clear {
    font-size: 24px;
  }

  .komodo-modal-tooltip {
    margin-left: 8px;
    margin-right: auto;
  }

  .komodo-modal-info {
    margin-left: auto;
    margin-right: 0;
  }
}

.inline-close {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.komodo-modal-card-close,
.komodo-modal-card-header,
.komodo-modal-card-body {
  padding: 1em;
}

.komodo-modal-card-title {
  @include k2-font-headline-headline-1;

  margin: 0.5rem 0;
  background-color: white;
  white-space: normal;
  padding-left: 1em;
  padding-right: 1em;
}

.komodo-modal-card-header {
  border-bottom: 1px solid $gray-4-24;

  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.komodo-modal-card-footer-wrapper {
  border-top: 1px solid $gray-4-24;
}

.komodo-modal-card-footer {
  align-items: center;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
}

.komodo-modal-card-body {
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;

  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
}
</style>

<template>
  <i
    :class="[iconClass, { clickable }, `komodo-icon-${size}`]"
    :data-icon="iconSet === IconSet.Material && icon"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Size } from 'shared/lib/component_size.ts'

export { Size } from 'shared/lib/component_size.ts'

export enum IconSet {
  Fabric = 'fabric',
  Material = 'material'
}

export default defineComponent({
  name: 'KomodoIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    iconSet: {
      type: String,
      default: IconSet.Material
    },
    size: {
      type: String,
      default: Size.Medium
    }
  },
  setup (props, { attrs }) {
    const iconClass = computed(() => {
      if (props.iconSet === IconSet.Material) {
        return ['komodo-icon ', `komodo-icon-${props.icon}`].join(' ')
      }
      return ['ms-Fabric', 'ms-Icon', 'komodo-icon-fabric', `ms-Icon--${props.icon}`, `komodo-icon-${props.icon}`].join(' ')
    })

    const clickable = computed(() => {
      return attrs.onClick !== undefined
    })

    return {
      iconClass,
      IconSet,
      clickable
    }
  }
})
</script>

<style scoped lang="scss">
.komodo-icon-fabric {
  direction: ltr;
  display: inline-block;
  font-size: 24px;

  &.clickable {
    cursor: pointer;
  }
}

/**
 * These are recommended by Google when self hosting the Material Icons font.
 *
 * https://google.github.io/material-design-icons/#setup-method-2-self-hosting
 */
.komodo-icon {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  user-select: none;
  white-space: nowrap;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizelegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.clickable {
    cursor: pointer;
  }
}

.komodo-icon-small {
  font-size: 16px;
}

.komodo-icon-medium {
  font-size: 24px;
}

// Prevent an element from being selected and copied with CSS
// https://danoc.me/blog/css-prevent-copy/
[data-icon]::before {
  content: attr(data-icon);
}
</style>
